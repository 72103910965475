<template>
  <div
    class="px-4 py-24 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-0 lg:px-8 lg:py-0"
  >
    <div class="p-8 rounded shadow-xl sm:p-16 dark:bg-gray-800">
      <h2 class="text-4xl pb-7 font-bold uppercase dark:text-white">Statistics</h2>
      <div class="grid row-gap-8 sm:grid-cols-2 py-3 divide-x divide-gray-500">
        <div class="text-center">
          <h6 class="text-4xl font-bold text-blue-400">{{ stats[0].nr_maps }}</h6>
          <p class="font-bold text-gray-500 dark:text-gray-100 uppercase">
            Maps
          </p>
        </div>
        <div class="text-center">
          <h6 class="text-4xl font-bold text-blue-400">{{ stats[0].nr_mods }}</h6>
          <p class="font-bold text-gray-500 dark:text-gray-100 uppercase">
            Mods
          </p>
        </div>
        <!-- <div class="text-center">
          <h6 class="text-4xl font-bold text-blue-400">432</h6>
          <p class="font-bold text-gray-500 dark:text-gray-100 uppercase">
            Skins
          </p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Stats",
  data() {
      return {
          stats: {},
      }
  },
  methods: {
    async fetchStats() {
      try {
        const url = `https://mohdb-api.appelpitje.be/count/all`;
        // const url = `http://localhost:8000/count/all`;
        const response = await axios.get(url);
        const results = response.data;
        this.stats = response.data;
        console.log(this.stats)
      } catch (err) {
        if (err.response) {
          console.log("Server Error:", err);
        } else if (err.request) {
          console.log("Network Error:", err);
        } else {
          console.log("Client Error:", err);
        }
      }
    },
  },
  mounted() {
      this.fetchStats();
  },
};
</script>