<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="relative bg-white dark:bg-gray-900">
    <div class="px-4 sm:px-6">
      <div
        class="flex justify-between items-center border-b-2 border-gray-100 dark:border-gray-200 py-6 md:justify-start md:space-x-10"
      >
        <div class="flex justify-start lg:w-0 lg:flex-1">
          <router-link to="/">
            <span class="sr-only">MOHDB</span>
            <h1 class="dark:text-white title-font font-medium text-2xl">
              MOH<span class="text-blue-500">DB</span>
            </h1>
          </router-link>
        </div>
        <div class="-mr-2 -my-2 md:hidden">
          <button
            @click="toggle"
            type="button"
            class="bg-white dark:bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 dark:text-gray-100 hover:text-gray-500 dark:hover:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            aria-expanded="false"
          >
            <span class="sr-only">Open menu</span>
            <!-- Heroicon name: outline/menu -->
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <nav class="hidden md:flex space-x-10">
          <router-link
            to="/"
            href="#"
            class="text-base font-medium uppercase text-gray-500 dark:text-white hover:text-gray-900"
          >
            Home
          </router-link>
          <router-link
            to="/mods"
            class="text-base font-medium uppercase text-gray-500 dark:text-white hover:text-gray-900"
          >
            Mods
          </router-link>
          <router-link
            to="/maps"
            class="text-base font-medium uppercase text-gray-500 dark:text-white hover:text-gray-900"
          >
            Maps
          </router-link>
          <!-- <router-link
            to="/skins"
            class="text-base font-medium uppercase text-gray-500 dark:text-white hover:text-gray-900"
          >
            Skins
          </router-link>
          <router-link
            to="/tools"
            class="text-base font-medium uppercase text-gray-500 dark:text-white hover:text-gray-900"
          >
            Tools
          </router-link> -->
        </nav>
        <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          <svg
            @click="toggleDarkmode"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            :class="darkmode ? 'hidden' : ''"
            class="h-6 w-6 cursor-pointer"
            id="toggle-dark"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
            />
          </svg>
          <!-- Sun SVG from Heroicons -->
          <svg
            @click="toggleDarkmode"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="white"
            :class="darkmode ? '' : 'hidden'"
            class="h-6 w-6 cursor-pointer"
            id="toggle-light"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
            />
          </svg>
          <a
            href="#"
            class="ml-8 whitespace-nowrap text-base font-medium text-gray-500 dark:text-gray-100 hover:text-gray-900 dark:hover:text-gray-50"
          >
            Sign in
          </a>
          <a
            href="#"
            class="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-500 hover:bg-blue-700"
          >
            Sign up
          </a>
        </div>
      </div>
    </div>

    <!--
    Mobile menu, show/hide based on mobile menu state.

    Entering: "duration-200 ease-out"
      From: "opacity-0 scale-95"
      To: "opacity-100 scale-100"
    Leaving: "duration-100 ease-in"
      From: "opacity-100 scale-100"
      To: "opacity-0 scale-95"
  -->
    <div
      :class="open ? 'block' : 'hidden'"
      class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
    >
      <div
        class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50 dark:bg-gray-800"
      >
        <div class="pt-5 pb-6 px-5">
          <div class="flex items-center justify-between">
            <div>
              <router-link to="/">
                <span class="sr-only">MOHDB</span>
                <h1 class="dark:text-white text-xl">
                  MOH<span class="text-blue-500">DB</span>
                </h1>
              </router-link>
            </div>
            <div class="-mr-2">
              <button
                @click="toggle"
                type="button"
                class="bg-white dark:bg-gray-600 dark:text-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              >
                <span class="sr-only">Close menu</span>
                <!-- Heroicon name: outline/x -->
                <svg
                  class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="mt-6">
            <nav class="grid gap-y-8">
              <router-link
                @click.native="toggle"
                to="/"
                href="#"
                class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 dark:hover:bg-gray-600 text-base font-medium text-gray-900 dark:text-white"
              >
                Home
              </router-link>
              <router-link
                @click.native="toggle"
                to="/mods"
                href="#"
                class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 dark:hover:bg-gray-600 text-base font-medium text-gray-900 dark:text-white"
              >
                Mods
              </router-link>
              <router-link
                @click.native="toggle"
                to="/maps"
                href="#"
                class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 dark:hover:bg-gray-600 text-base font-medium text-gray-900 dark:text-white"
              >
                Maps
              </router-link>
              <!-- <router-link
                @click.native="toggle"
                to="/skins"
                href="#"
                class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 dark:hover:bg-gray-600 text-base font-medium text-gray-900 dark:text-white"
              >
                Skins
              </router-link>
              <router-link
                @click.native="toggle"
                to="/tools"
                href="#"
                class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 dark:hover:bg-gray-600 text-base font-medium text-gray-900 dark:text-white"
              >
                Tools
              </router-link> -->
            </nav>
          </div>
        </div>
        <div class="py-6 px-5 space-y-6">
          <div>
            <a
              href="#"
              class="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-500"
            >
              Sign up
            </a>
            <p class="mt-6 text-center text-base font-medium text-gray-500 dark:text-white">
              Existing user?
              <a href="#" class="text-blue-600 hover:text-blue-500">
                Sign in
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data: function () {
    return {
      open: false,
      darkmode: false,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    toggleDarkmode() {
      this.darkmode = !this.darkmode;
    },
  },
  watch: {
    darkmode: function () {
      this.$emit("darkmode", this.darkmode);
    },
  },
  mounted() {
    const mode = localStorage.getItem("darkmode");
    if (mode) {
      if (mode == "true") {
        this.darkmode = true;
      } else {
        this.darkmode = false;
      }
    }
  },
};
</script>

<style scoped>
</style>