<template>
  <div :class="darkmode ? 'dark' : ''" class="flex flex-col min-h-screen">
    <Header @darkmode="darkmodeValue" />
    <div class="dark:bg-gray-900 flex-grow">
      <main class="container flex-grow mx-auto">
        <slot />
      </main>
    </div>
    <Footer class="dark:bg-gray-900 dark:text-white" />
  </div>
</template>

<script>
import Header from "@/components/Layout/Header.vue";
import Footer from "@/components/Layout/Footer.vue";
import Stats from "@/components/Layout/Stats.vue";

export default {
  components: {
    Header,
    Footer,
    Stats,
  },
  data: function () {
    return {
      darkmode: false,
    };
  },
  methods: {
    darkmodeValue: function (params) {
      this.darkmode = params;
      localStorage.setItem("darkmode", this.darkmode.toString());
    },
  },
  mounted() {
    const mode = localStorage.getItem("darkmode");
    if (mode) {
      if (mode == "true") {
        this.darkmode = true;
      } else {
        this.darkmode = false;
      }
    }
  },
};
</script>