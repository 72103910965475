import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/mods',
    name: 'Mods',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Mods.vue')
    }
  },
  {
    path: '/maps',
    name: 'Maps',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Maps.vue')
    }
  },
  {
    path: '/skins',
    name: 'Skins',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Skins.vue')
    }
  },
  {
    path: '/tools',
    name: 'Tools',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/Tools.vue')
    }
  },
  { path: "*",
    name: '404',
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/404.vue')
  }
}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
