<template>
<div>
  <Hero class="pb-24"/>
  <Stats />
</div>
</template>

<script>
import Hero from "@/components/Layout/Hero.vue"
import Stats from "@/components/Layout/Stats.vue"

export default {
  name: "Home",
    components: {
    Hero,
    Stats,
  },
};
</script>
