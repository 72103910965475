import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'

import './assets/styles/index.css';
import router from './router'

Vue.config.productionTip = false

Vue.use(VueRouter);

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
